/* Simple Lightbox (http://dbrekalo.github.io/simpleLightbox) */
$('.lightbox-gallery1 a').simpleLightbox();
$('.lightbox-single-link1').simpleLightbox();
//$('.lightbox-single-link2').simpleLightbox();
//$('.lightbox-single-link3').simpleLightbox();


/*
 * YF: repair problem with chrome flickering
 * (!) ADDED CODE TO ext-simplelightbox.js: from line 355
 */
$('.lightbox-gallery1 a').click(function () {
    $('#yf-ken-burns').carousel('pause');
    $('#yf-ken-burns').removeClass('kb_elastic');
    $('#yf-ken-burns').removeClass('kb_wrapper');
});
$('.lightbox-single-link1').click(function () {
    $('#yf-ken-burns').carousel('pause');
    $('#yf-ken-burns').removeClass('kb_elastic');
    $('#yf-ken-burns').removeClass('kb_wrapper');
});


/* Smooth anchor scroll: to target */
$('a[href^="#scrollto_"]').click(function(){
    var the_id = $(this).attr("href");

    $('html, body').animate({
        scrollTop:$(the_id).offset().top - 15
    }, 'slow');
    return false;
});
/* Smooth anchor scroll: to top */
$('a[href^="#scrolltotop"]').click(function(){
    $('html, body').animate({
        scrollTop:0
    }, 'slow');
    return false;
});


/* Accordion icons */
$(document).ready(function() {
    $('.collapse.in').prev('.panel-heading').addClass('active');
    $('#accordion, #bs-collapse')
        .on('show.bs.collapse', function(a) {
            $(a.target).prev('.panel-heading').addClass('active');
        })
        .on('hide.bs.collapse', function(a) {
            $(a.target).prev('.panel-heading').removeClass('active');
        });

});


/* News-Popup: Close on click + removal */
$('.yf-popup-close').click(function () {
    //$('.yf-popup').hide();
    $('.yf-popup').addClass("fadeOutLeft");

    setTimeout(function(){
        $('.yf-popup').remove();
    }, 250);
});

/* Table collapse  */
$(".accordion-toggle.yf-primary").click(function(){
    $(this).toggleClass("yf-down");
});

